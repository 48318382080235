import React from 'react'
import { Grid } from '@material-ui/core'
import TitleComponent from '../General/TitleComponent'
import VideoComponent from '../General/VideoComponent'
import SectionWrapper from '../General/SectionWrapper'


export default function AboutVideo({
    title = 'Marketing Superhero', secondaryTitles = ['Superhero'], aboutVideo='https://www.youtube.com/embed/-pzLRH4nep8'
}) {
    return <SectionWrapper sectionId='about-video' >
        <Grid container justifyContent='center' spacing={4}>
            <Grid item xs={12}>
                <div style={{
                    paddingBottom: '3rem'
                }}>
                    <TitleComponent
                        title={title} secondaryTitles={secondaryTitles}
                        smallTitle="About"
                        subHeading="Building a magnetic marketing is now super easy! Our marketing strategies are innovative, trending and creative to put an end to all your marketing problems"
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={10}>
                <VideoComponent url={aboutVideo} title='About Vybinex' />
            </Grid>
        </Grid>
    </SectionWrapper>
}
