import React, {useState, useEffect} from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import VisibilitySensor from 'react-visibility-sensor';
import SectionWrapper from '../General/SectionWrapper';
import CountUp from 'react-countup'
import { fetchData } from '../../utitlities/helperFunctions'

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative'
    },
    numberContainer: {
        padding: "2rem 0px 1rem",
        [theme.breakpoints.up('sm')]: {
            padding: '3rem 0px 2rem'
        },
        [theme.breakpoints.up('md')]: {
            padding: '5rem 0px 2rem'
        }
    },
}))



function NumberTile({ value, label, color, suffix }) {
    const [viewportEntered, setViewPortEntered] = React.useState(false)
    return <Grid container direction='column' alignItems='center'>
        <Grid item>
            <Typography variant='h3' component='p' color={color}>
                <VisibilitySensor onChange={(visible) => {
                    if (visible) {
                        setViewPortEntered(visible)
                    }
                }} delayedCall>
                    <CountUp end={viewportEntered ? value : 0} duration={2} suffix={suffix} />
                </VisibilitySensor>
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant='body1' color='primary'>
                {label}
            </Typography>
        </Grid>
    </Grid>
}


export default function NumberStat({ allOverData = [] }) {        
    const [HomeData,SetHomeData] = useState()             
    useEffect(() => { 
        fetchData(`home`).then(x => SetHomeData(x) )
    }, []);
    allOverData = HomeData ? HomeData.stats : allOverData     
    const classes = useStyles()
    const numberList = allOverData
    return <SectionWrapper sectionId='testimonials'>
        <Grid container alignItems='center' spacing={4}>            
            <Grid item xs={12}>
                <div className={classes.numberContainer}>
                    <Grid container spacing={2}>
                        {
                            numberList.map(item => {
                                return <Grid item xs={6} sm={3}>
                                    <NumberTile {...item} />
                                </Grid>
                            })
                        }
                    </Grid>
                </div>
            </Grid>
        </Grid>
    </SectionWrapper>
}