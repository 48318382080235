import React, {useState, useEffect} from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import TitleComponent from '../General/TitleComponent'
import LinkComponent from '../Gatsby/LinkComponent'
import SectionWrapper from '../General/SectionWrapper' 
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { fetchData } from '../../utitlities/helperFunctions'

const useStyles = makeStyles(theme => ({
    cardContainer: {
        // borderBottom: '1px solid #e8e8e8',
        // border: "solid 1px red",
        padding: "1.15rem 0px",
        [theme.breakpoints.up("sm")]: {
            padding: '1.5rem 0px'
        },
        "& svg": {
            fill: theme.palette.primary.main
        }
    },
    iconImage: {
        width: "75px"
    }
}))

function ServiceCardRender({ list = [], linkPrefix = '' }) {
    const classes = useStyles()
    return <Grid container spacing={1}>
        {
            list.map(item => {
                const { title, id, slug, excerpt } = item
                const route = `${!!linkPrefix ? `/${linkPrefix}/` : "/services/"}${slug}${!!linkPrefix ? "/" : ""}`
                return <Grid item key={id} xs={12}>
                    <div className={classes.cardContainer}>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item xs={3} sm={2} md={3}>
                                <img src={item.icon_url} alt={item.id} className={classes.iconImage}/>
                            </Grid>
                            <Grid item xs={9} sm={10} md={9}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <LinkComponent route={route} title={title} styles={{
                                            fontSize: '1.1rem'
                                        }} />
                                    </Grid>
                                    {
                                        !!excerpt &&
                                        <Grid item xs={12}>
                                            <Typography variant='body2' color='primary'>{excerpt}</Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                </Grid>
            })
        }
    </Grid>
}

export default function TopServices({
    title = 'Grow High With Our Services', secondaryTitles = ['Grow', 'High'],
}) {
    const serviceQuery = useStaticQuery(graphql`
    query TopServiceQuery {
        services: allApiServices {
            edges {
              node {
                
                links {
                  id          
                  slug
                  shortDescription                    
                  excerpt
                  title
                  icon_url
                }
              }
            }
          }
        serviceBanner: allFile(
            filter: {sourceInstanceName: {eq: "images"}, name: {eq: "TopServices"}}
          ) {
            edges {
              node {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                }
              }
            }
          }
      }
    `)
    const [serviceData,SetData] = useState()     
    useEffect(() => { 
      fetchData(`services`)
      .then(x => SetData(x) )         
    }, []);    
    const bannerImage = serviceQuery.serviceBanner.edges[0].node
    const services = serviceData ? serviceData.links : serviceQuery.services.edges[0].node.links
    const linkPrefix = ''
    const list1 = [...services].splice(0, 4)
    const list2 = [...services].splice(4)
    return <SectionWrapper sectionId='top-services'>
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <div style={{
                    paddingBottom: '3rem'
                }}>
                    <TitleComponent
                        title={title} secondaryTitles={secondaryTitles}
                        smallTitle={'Service'}
                        subHeading={"No two businesses are the same! Our services are curated specifically to your brand, give an ultra-premium experience & help your brand reach greater heights!"}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item xs={12} md={4}>
                        <ServiceCardRender list={list1} linkPrefix={linkPrefix} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <GatsbyImage image={getImage(bannerImage)} alt="top-services" />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ServiceCardRender list={list2} linkPrefix={linkPrefix} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </SectionWrapper>
}
