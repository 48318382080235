import React, {useState, useEffect} from 'react'
import { Grid, makeStyles, Hidden, IconButton } from '@material-ui/core'
import SectionWrapper from '../General/SectionWrapper'
import { useStaticQuery, graphql } from 'gatsby'  
import { fetchData } from '../../utitlities/helperFunctions'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
import LinkComponent from '../Gatsby/LinkComponent'
import TitleComponent from '../General/TitleComponent'
import NextIcon from '@material-ui/icons/NavigateNext'
import PrevIcon from '@material-ui/icons/NavigateBefore'

const useStyles = makeStyles(theme => ({
  container: {
    padding: "1rem",
    [theme.breakpoints.up("sm")]: {
      padding: "3rem 2rem"
    },
    [theme.breakpoints.up('md')]: {
      width: '90%',
      padding: "5rem 2rem"
    }
  },
  imageContainer: {
    padding: "15px 20px 25px",
    [theme.breakpoints.down('xs')]: {
      width: '100% !important'
    }
  },
  IconButton: {
    padding: '0px',
    [theme.breakpoints.up("sm")]: {
      padding: '12px'
    }
  },
  buttonContainer: {
    display: 'flex', justifyContent: "center",
    paddingBottom: "40px",
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '40px'
    }
  },

  actimage: {
      maxWidth: '100%',
  },

}))

function NextArrow(props) {
  const classes = useStyles()
  const { onClick } = props;
  return (
    <IconButton onClick={onClick} className={classes.IconButton} >
      <NextIcon color='primary' />
    </IconButton>
  );
}

function PrevArrow(props) {
  const classes = useStyles()
  const { onClick } = props;
  return (
    <IconButton onClick={onClick} className={classes.IconButton} >
      <PrevIcon color='primary' />
    </IconButton>
  );
}

function Clients({ showButton = false }) {
  
  const clientsQuery = useStaticQuery(graphql`
  
    query ClientsQuery {
        clients: allApiClients {
          edges {
            node {
              clients {
                id
                label
                order
                photo_url
              }
            }
          }
        }       
      }           
    `)

  const [ClientData,SetData] = useState()     
    useEffect(() => { 
      fetchData(`clients`)
      .then(x => SetData(x) )         
  }, []);  
  const clients = ClientData ? ClientData.clients : clientsQuery.clients.edges[0].node.clients   
  const classes = useStyles()   

  const settings = {
    dots: false,
    rows: 2,
    slidesPerRow: 5,
    infinite: true,
    autoplay: false,
    swipeToSlide: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesPerRow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesPerRow: 2,
        },
      }
    ]
  }

  return <SectionWrapper sectionId='clients' className={classes.container} >
    {
      showButton &&
      <Hidden mdUp implementation='css'>
        <div className={classes.buttonContainer}>
          <LinkComponent
            showAsButton={true}
            route="/marketing-secrets"
            title="Marketing Secrets"
          />
        </div>
      </Hidden>
    }
    
    <Grid container spacing={4}>
      <Grid item xs={12}>
          <div style={{
              paddingBottom: '3rem'
          }}>
              <TitleComponent
                  title={"Our Super Clients"} secondaryTitles={"Our,Super"}
                  smallTitle={'Clients'}
              />
          </div>
      </Grid>

      <Slider {...settings} 
        style={{
              width: '100%',
              paddingBottom: '3rem',
          }}
        >
      {
        clients.sort((a, b) => a.order - b.order).map(item => {
          return <div className={classes.imageContainer} key={item.id}>
            <img src={item.photo_url} alt={item.label} className={classes.actimage} imgClassName={classes.actimage} /> 
          </div>
        })
      }
    </Slider>
    </Grid>
   
  </SectionWrapper >
}

export default Clients;