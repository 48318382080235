import React, {useState, useEffect} from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, makeStyles, Typography, Hidden } from '@material-ui/core'
import { generalTextColor } from '../../utitlities/Configurations'; 
import LinkButton from '../General/Buttons/Link';
import NextIcon from '@material-ui/icons/NavigateNext'
import PrevIcon from '@material-ui/icons/NavigateBefore'
import SectionWrapper from '../General/SectionWrapper';
import QuoteLeft from '../General/SocialIcons/QuoteLeft'
import { useStaticQuery, graphql } from 'gatsby'
import { fetchData } from '../../utitlities/helperFunctions'
import VideoComponent from '../General/VideoComponent'
const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative'
    },
    shape: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        zIndex: -1,
        width: "100%"
    },
    quote: {
        width: "30px",
        '& svg': {
            fill: theme.palette.primary.main
        }
    },
    avatar: {
        maxWidth: "100%",
        // maxWidth: "6rem",
        // height: '4rem',
        // borderRadius: '50%',
        overflow: 'hidden',
        margin: "5px",
        [theme.breakpoints.up('sm')]: {
            width: "5rem",
            // height: '5rem',
        },
        [theme.breakpoints.up('md')]: {
            width: "7rem",
            // height: '7rem',
        },
        [theme.breakpoints.down('xs')]: {
            // width: "64px",
            width: "7rem",
            // height: '7rem',
        }
    }
}))


function TestimonyCard(props) {
    const { testimony, name, designation, imageUrl } = props
    const classes = useStyles()
    return <Grid container spacing={2}>
        <Grid item>
            <div className={classes.quote}>
                <QuoteLeft />
            </div>
        </Grid>
        <Grid item xs={12}>
            <Typography variant='body1' style={{
                color: generalTextColor
            }}>{testimony}</Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container alignItems='center' spacing={2}>
                <Grid item>
                    {/* <Avatar src={image} /> */}
                    <img src={imageUrl}  alt={name} className={classes.avatar} width= '100%' objectFit= 'contain'  />
                     
                </Grid>
                <Grid item>
                    <Grid container >
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{
                                color: generalTextColor,
                                fontWeight: 600
                            }}>{name}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2' style={{
                                color: generalTextColor
                            }}>{designation}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}



function SetVideoUrls(data,OrgVideo) {
    if (OrgVideo.length && data.length<2) {
      data.push({"id":2,"video_link":OrgVideo[0].video_link})       
    }
    return data     
}
export default function Testimonials({ allOverData = [], FromService= false, SerTestimonials = [], videoData = [] }) {
    const testimonialQuery = useStaticQuery(graphql`
    query TestimonialQuery {
        testimonials: allApiTestimonials {
            edges {
              node {
                testimonials {
                  id
                  designation
                  name
                  order
                  testimony
                  photo_url
                }
              }
            }
          }          
      }      
    `)
    
    const [TestimonialsData,SetData] = useState()     
    useEffect(() => { 
      fetchData(`testimonials`)        
      .then(x => SetData(x) )         
     }, []);
     
    const testimonials = FromService ?  SerTestimonials : (TestimonialsData ? TestimonialsData.testimonials : testimonialQuery.testimonials.edges[0].node.testimonials)  
    const TestOrgVideo = (TestimonialsData && TestimonialsData.testimonial_videos) ? TestimonialsData.testimonial_videos : []   
    const TestimonialVideo = (FromService && videoData.length) ?  SetVideoUrls(videoData,TestOrgVideo) : TestOrgVideo
    const sliderRef = React.useRef(null)
    const settings = {
        dots: false,
        infinite: true,
        autoplay: false,
        swipeToSlide: true,
        arrows: false
    }
    return <SectionWrapper sectionId='testimonials'>
        <Grid container alignItems='center' spacing={4}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Hidden mdUp implementation='css'>
                        <Grid item xs={12}>
                            <Typography style={{ textTransform: 'uppercase', fontWeight: 600 }} color='secondary' variant="body1"  >
                                Testimonials
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h2' color='primary'>
                                Our Clients Who Speak For Our <Typography variant='h2' component='span' color='secondary'>Superpower</Typography>
                            </Typography>
                        </Grid>
                    </Hidden>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>                
                {
                    TestimonialVideo.map(item => {
                            
                        return <div>
                            <Grid item xs={12}  sm={12} md={12}>
                                <VideoComponent url={item.video_link} title='testimonials' />
                            </Grid> <p></p>
                            </div>
    
                    })
                }
            </Grid>
            <Grid item xs={12} md={6} >
                <Grid container spacing={3}>
                    <Hidden smDown implementation='css'>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={{ textTransform: 'uppercase', fontWeight: 600 }} color='secondary' variant="body1"  >
                                        Testimonials
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='h2' color='primary'>
                                        Our Clients Who Speak For Our <Typography variant='h2' component='span' color='secondary'>Superpower</Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>
                    {/* <Grid item xs={12}>
                        <Typography variant='h6' component='p' style={{ fontWeight: 'normal' }} color='primary'>
                            We have grown over XYZ magnetic businesses
                        </Typography>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Slider ref={sliderRef} {...settings}>
                            {
                                testimonials.map(item => {
                                     
                                    return <TestimonyCard {...item} imageUrl={item.photo_url}   />
                                })
                            }
                        </Slider>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item>
                                <LinkButton
                                    label='Previous'
                                    buttonProps={{
                                        id: "prev",
                                        startIcon: <PrevIcon color='secondary' fontSize='small' />,
                                        onClick: () => {
                                            if (!!sliderRef.current) {
                                                sliderRef.current.slickPrev()
                                            }
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <LinkButton
                                    label='Next'
                                    buttonProps={{
                                        id: "next",
                                        endIcon: <NextIcon color='secondary' fontSize='small' />,
                                        onClick: () => {
                                            if (!!sliderRef.current) {
                                                sliderRef.current.slickNext()
                                            }
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>            
        </Grid>
    </SectionWrapper>
}