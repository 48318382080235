import React, {useState, useEffect} from 'react'
import Layout from '../components/Layout'
import Banner from '../components/Banner'
import TopServices from '../components/Home/TopServices'
import Clients from '../components/Home/Clients'
import Testimonials from '../components/Home/Testimonials'
import NumbersStat from '../components/Home/NumbersStat'
import Portfolio from '../components/Home/Portfolio'
import AboutVideo from "../components/Home/AboutVideo"
import ContactForm from '../components/Contact/Form'
import { graphql } from 'gatsby'
import { getStartedFormDivId, getStartedFormId } from '../utitlities/Configurations'
// import CashbackBanner from "../components/Home/CashbackBanner"
import { fetchData } from '../utitlities/helperFunctions'

const IndexPage = ({ data }) => {
  const [HomeData,SetData] = useState()     
  useEffect(() => { 
    fetchData(`home`)
    .then(x => SetData(x) )         
}, []);
  const homePageData = HomeData ? HomeData : data.home.edges[0].node
  const bannerImageData = {url: data.bannerImage.edges[0].node.childImageSharp.original.src}   
  const pageSeo = homePageData.Seo
  return (
    <Layout pageSeo={pageSeo}>
      <Banner
        bannerId="home-banner"
        heading={homePageData.heading}
        secondaryHeadings={homePageData.headingHighlights}
        subHeading={homePageData.subHeading}
        imageComponent={bannerImageData}
        imageUrl={true}
        showFormInsteadButton={true}
        formDivId={getStartedFormDivId}
        formId={getStartedFormId}
        showIcon={true}
      />
      <NumbersStat allOverData={homePageData.stats}  />
      <AboutVideo aboutVideo={homePageData.aboutVideoUrl}/>
      <Testimonials />
      <Clients showButton={true} />
      <TopServices />
      <Portfolio
        clipList={true}
      />
      {/* <CashbackBanner /> */}
      <ContactForm title="Get Free Quote Now" secondaryTitles={['Free', 'Quote']} smallTitle="Contact Us" showTitle={true} titleComponent="h2" titleVariant="h2" />
    </Layout>
  )
}

export const query = graphql`
query HomePageQuery {
  home: allApiHome {
    edges {
      node {
        id
        Seo {
          description
          title
        }
        heading
        headingHighlights
        subHeading
        stats {
          color
          id
          label
          suffix
          value
        }
        aboutVideoUrl
      }
    }
  }
  bannerImage: allFile(
    filter: {sourceInstanceName: {eq: "images"}, name: {eq: "HomeBanner"}}
  ) {
    edges {
      node {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  } 
}
`

export default IndexPage
